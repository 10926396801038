if( $( '.news-card' ).length ) {
  $( window ).on( 'load', function() {
    var hash = window.location.hash;  
    var hashCategory = hash.replace( '#', '' );

    //Check if the window has a hash on load and hide the cards that don't have the hash category
    if( hashCategory.length ){
      $( '.news-card' ).each( function() {
        var card = $( this );
        if ( card.hasClass( hashCategory ) || hashCategory == 'all' ) {
          card.removeClass( 'hide-card' );
        } else {
          card.addClass( 'hide-card' );
        }
      }); 
    }
    //Sorts the main filtering categories 
    $( '.js-sort a' ).on( 'click', function ( e ) {
      e.preventDefault();

      var $this = $( this );
      var category = $this.data( 'category' );
      var tag = $this.data( 'tag' );

      $this.siblings().removeClass( 'active' );
      $this.addClass( 'active' );
      window.location.hash = category;  //update the hash 

      //Foreach card we check if the catagory slug exists in the class attributes, hide it if it doesn't have the category class
      $( '.news-card' ).each( function() {
        var card = $( this );

        if ( card.hasClass( category ) || category == 'all' ) {
          card.each( function() {
            card.removeClass( 'hide-card' );
          });
        } else {
          card.addClass( 'hide-card' );
        }
      });    
    }); 

  //Filters funtion for the acf tags
    function myFunction( item, index ) {
      $( '.' + item ).removeClass( 'hide-card' );
      $( '.' + item ).addClass( 'active-tag' );
      $( '#clearFilter' ).addClass( 'active-tag' );
    }
    
    function filterEngine(){
      // Put all checked filter value in an array 
      var choices= [];
      $.each( $( "[name='golf-holes']:checked" ), function(){
        choices.push( $( this ).val() ); 
      });
      
      // Filter list
      if( choices.length === 0 ){
        $( '.news-card' ).removeClass( 'hide-card' );
        $( '.tag-btn' ).removeClass( 'active-tag' );
        $( '#clearFilter' ).removeClass( 'active-tag' );
      } else {
        $( '.news-card' ).addClass( 'hide-card' );
        $( '.tag-btn' ).removeClass( 'active-tag' );
        choices.forEach( myFunction );
      }
    };

    function hashChange(){
    
      //Get array of checked values
      var checked = $( "[name='golf-holes']:checked" ).map( function() {
        return $( this ).val();
      }).get();

      //Update hash based on array of checked values
      var checkedHash = checked.toString().replaceAll( ',', '#' );
      window.location.hash = checkedHash;   

    };

    //Tag click runs filters and hash changes
    $( '.js-tag input' ).on( 'click', function () {
      filterEngine();
      hashChange(); 
    }); 

    //Clicking an active tag clears the tag, reruns filter and hash functions
    $( '.js-tag-active button' ).on( 'click', function () {

      current = $( this ).val();

      $( '.js-tag' ).find( "[value='" + current + "']" ).prop( 'checked', false);
      $( this ).removeClass( 'active-tag' );

      filterEngine();
      hashChange();
    });
    
    //Clears all active tags and closes dropdown
    $( '#clearFilter' ).on( 'click', function( e ) {
      e.preventDefault;
      $( this ).parent().siblings( '.js-tag' ).find( "[name='golf-holes']" ).prop( 'checked', false );
      $( '.tag-btn' ).removeClass( 'active-tag' );
      filterEngine();
      window.location.hash = 'all';   
      $( '.dropdown' ).removeClass( 'open' );
      $( '.dropdown-info' ).removeClass( 'open' );
    });

    $( '.dropdown' ).on( 'click', function( e ) {
      e.preventDefault;
      $( '.dropdown' ).toggleClass( 'open' );
      $( '.dropdown-info' ).toggleClass( 'open' );
    })
  });
}
