import $ from 'jquery'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css';

if( $( '.amenities-map-section' ).length ){
  mapboxgl.accessToken = 'pk.eyJ1IjoiZmxpcHBkZXZzIiwiYSI6ImNrc2hxc2x0ZDF3eGQyb29hZDNndWhuZXcifQ.NLQN5AjdofpVMB8SBRgDgA';
  const mapInfo = document.querySelectorAll( '.map-info' )
  const $mapLong = $( mapInfo ).find( '.map-lon' );
  const $mapLat = $( mapInfo ).find( '.map-lat' );
  const markerZoom = 17;
  
  const map = new mapboxgl.Map( {
  
  container: 'map', // container ID
  style: 'mapbox://styles/flippdevs/cl0cv21nh00gr15oi5k7h4rkk', // style URL
  center: [-106.660, 52.079], // starting position [lng, lat]
  zoom: 15, // starting zoom

  cooperativeGestures: true,

  } );

  mapInfo.forEach( ( elem, index ) => {

    // Create Marker Element
    const el = document.createElement( 'div' )
    el.className = 'marker'
    el.dataset.slug = elem.dataset.slug

    // Create Number for Marker Element
    const mapMarkerNumber = document.createElement( 'p' )
    mapMarkerNumber.className = 'map-marker-number'
    mapMarkerNumber.textContent = $( mapInfo ).find( '.map-number' )[index].textContent
    
    el.appendChild( mapMarkerNumber ) // Add to Marker Element

    const popUp = new mapboxgl.Popup( { offset: 2 } ) // add popups
    .setHTML(
      `<h2 class="headline-small map-box-heading">${ $( mapInfo ).find( '.map-title' )[index].textContent }</h2>`
    )

    // Create a new marker.
    const marker = new mapboxgl.Marker(el)
    .setLngLat( [$( mapInfo ).find( '.map-lon' )[index].textContent, $( mapInfo ).find( '.map-lat' )[index].textContent] )
    .setPopup( popUp )
    .addTo( map );
    
  } )

  // Linking Legend Marker to Pop-Up on Map
  const mapLegendItem = document.querySelectorAll( '.map-legend-item' )

  mapLegendItem.forEach( ( elem, index ) => {
    elem.addEventListener( 'click', () => {

      $( '.map-legend-title' ).removeClass('active')
      $( elem ).find( '.map-legend-title' ).addClass('active')

      $( '.mapboxgl-popup' ).remove() // Remove all existing pop ups
      
      // Mobile: Make drop down go back up
      $( '.map-legend' ).removeClass( 'map-legend-toggle' )
      $( '.show-more-button' ).removeClass( 'show-more-button-toggle' )

      // Add popup
      const legendPopUp = new mapboxgl.Popup( { offset: 2 } )
      .setHTML(
        `<h2 class="legend-popup headline-small map-box-heading">${ $( mapInfo ).find( '.map-title' )[index].textContent }</h2>`
      )
      .setLngLat([$mapLong[index].textContent, $mapLat[index].textContent])
      .addTo( map )
      
      // Map fly to location
      map.flyTo({
        center:[$mapLong[index].textContent, $mapLat[index].textContent],
        zoom: markerZoom
      })

    } )
  } )

  // Clicking Marker on Map will make it zoom in
  const legendMarker = document.querySelectorAll( '.marker' )
  const legendTitle = document.querySelectorAll( '.map-legend-title' )

  legendMarker.forEach( ( elem, index ) => {

    elem.addEventListener('click', function () {

      // Change title to active state
      $( '.map-legend-title' ).removeClass('active')
      if( elem.dataset.slug === legendTitle[index].dataset.slug ) {
        legendTitle[index].classList.add('active')
      }

      // Map fly to location
      map.flyTo({
        center: [$( mapInfo ).find( '.map-lon' )[index].textContent, $( mapInfo ).find( '.map-lat' )[index].textContent],
        zoom: markerZoom
      })
    })
  } )

  // Amenities List relative to Map
  const amenityListHeading = document.querySelectorAll('.amenities-heading')

  amenityListHeading.forEach( ( elem, index ) => {

    elem.addEventListener( 'click', function() {
      if( elem.dataset.slug === mapInfo[index].dataset.slug ) {

        // Heading changings color for active state
        $( '.map-legend-title' ).removeClass('active')
        legendTitle[index].classList.add('active')

        $( '.mapboxgl-popup' ).remove() // Remove all existing pop ups

        // Add popup
        const listPopUp = new mapboxgl.Popup( { offset: 2 } )
        .setHTML(
          `<h2 class="legend-popup headline-small map-box-heading">${ $( mapInfo ).find( '.map-title' )[index].textContent }</h2>`
        )
        .setLngLat([$( mapInfo ).find( '.map-lon' )[index].textContent, $( mapInfo ).find( '.map-lat' )[index].textContent])
        .addTo( map )
        
        // Map fly to location
        map.flyTo({
          center: [ $( mapInfo ).find( '.map-lon' )[index].textContent, $( mapInfo ).find( '.map-lat' )[index].textContent],
          zoom: markerZoom
        })
      }
    } )

  } )

  // Mobile Show More Button
  $( '.show-more-button' ).on( 'click', () => {
    $( '.map-legend' ).toggleClass( 'map-legend-toggle' )
    $( '.show-more-button' ).toggleClass( 'show-more-button-toggle' )
  } )

}